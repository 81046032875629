.minicart-bg {
	background-color: rgba(57, 55, 72, 0.22);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 76px;
	right: 0;
	z-index: 199;
}

.minicart-title {
	margin-bottom: 32px;
}

.minicart-content {
	position: absolute;
	min-width: 325px;
	max-height: 677px;
	right: 72px;
	top: 0;
	background: #ffffff;
	z-index: 200;
	padding: 32px 16px;
}

.minicart-price {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.price-title {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
}

.price-amount {
	font-weight: 700;
	font-size: 16px;
	line-height: 26px;
}

.minicart-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.viewbag-button {
	width: 140px;
	height: 43px;
	background: #ffffff;
	border: 1px solid #1d1f22;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
}

.checkout-button {
	width: 140px;
	height: 43px;
	background: #5ece7b;
	border: none;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	cursor: pointer;
}

.minicart-items-container {
	max-height: 465px;
	overflow-y: auto;
}

.minicart-item {
	width: 100%;
	height: auto;
	margin-bottom: 40px;
	display: grid;
	grid-template-columns: 3fr 2fr;
	gap: 8px;
}

.item-details-container {
	width: 100%;
	height: auto;
	display: grid;
	grid-template-columns: 5fr 1fr;
	gap: 1px;
}

.minicart-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.minicart-item-image {
	width: 121px;
	min-height: 190px;
	object-fit: contain;
}

.quantity-change-buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.quantity-change-buttons > button {
	width: 24px;
	height: 24px;
	background-color: #ffffff;
	border: 1px solid #1d1f22;
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.minicart-brand-name,
.minicart-product-name {
	font-weight: 300;
	font-size: 16px;
	line-height: 26px;
}

.minicart-price-details {
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
}
