.pdp-container {
	margin-top: 60px;
	display: grid;
	grid-template-columns: 1fr 3fr 2fr;
	gap: 20px;
}

/* Image thumbnails */

.pdp-thumbnails {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: left;
}

.pdp-image-thumbnail {
	width: 79px;
	height: 80px;
	object-fit: contain;
	margin-bottom: 32px;
	cursor: pointer;
}

.pdp-image-thumbnail:hover {
	box-shadow: 0px 4px 35px rgba(168, 172, 176, 0.19);
}

/* Main image section */

.pdp-current-image {
	width: 610px;
	height: 511px;
	object-fit: contain;
}

/* product descriptions */

.pdp-descriptions {
	margin-left: 50px;
}

.brand-name {
	font-weight: 600;
	font-size: 30px;
}

.pdp-product-name {
	font-weight: 400;
	font-size: 30px;
	padding: 16px 0 43px 0;
}

.pdp-price-details {
	font-weight: 700;
	font-size: 24px;
	margin: 10px 0 20px 0;
}

.pdp-add-button {
	background-color: #5ece7b;
	padding: 16px 32px;
	width: 292px;
	text-align: center;
	outline: none;
	border: none;
	font-weight: 600;
	color: #ffffff;
	font-size: 16px;
	text-transform: uppercase;
	margin: 0 0 20px 0;
	cursor: pointer;
}

.pdp-add-button:disabled {
	opacity: 0.8;
	cursor: not-allowed;
}

.pdp-innerhtml,
.pdp-innerhtml * {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	line-height: 26px;
}
