.cart-title {
	font-weight: 700;
	font-size: 32px;
	line-height: 40px;
	text-transform: uppercase;
	margin: 48px 0 55px 0;
}

.divider-line {
	border: 1px solid #e5e5e5;
	margin-bottom: 24px;
}

.cart-item {
	display: grid;
	grid-template-columns: 4fr 2fr;
	gap: 10px;
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.cart-item-right {
	display: grid;
	grid-template-columns: 1fr 4fr;
	gap: 5px;
	margin-bottom: 24px;
}

.cart-item-images {
	position: relative;
}

.pb-10 {
	padding-bottom: 10px;
}

.cart-item-image {
	width: 100%;
	height: auto;
	object-fit: contain;
	margin: 0 auto;
}

.cart-item-button {
	width: 45px;
	height: 45px;
	border: 1px solid #1d1f22;
	background-color: #ffffff;
	font-size: 24px;
	line-height: 160%;
	font-weight: 500;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.item-count {
	font-weight: 500;
	font-size: 24px;
	line-height: 160%;
}

.carousel-buttons {
	display: flex;
	width: fit-content;
	z-index: 99;
	position: absolute;
	bottom: 4%;
	right: 3%;
}

.c-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.73);
	width: 24px;
	height: 24px;
	margin-right: 8px;
	cursor: pointer;
}

.order-button {
	width: 279px;
	background: #5ece7b;
	padding: 16px 32px;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	text-transform: uppercase;
	color: #ffffff;
	border: none;
	outline: none;
	margin: 20px 0;
	cursor: pointer;
}

.sale-summary {
	display: grid;
	grid-template-columns: 1fr 3fr;
	gap: 2px;
	width: 279px;
}
