/* Global Styles */

* {
	font-family: "Raleway", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: #1d1f22;
}

body {
	margin-bottom: 100px;
}

/* Main page */

.page-container {
	width: 90%;
	margin: 0 auto;
}

.products-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 50px;
}

.header-text {
	font-size: 42px;
	font-weight: 400;
	text-transform: capitalize;
	padding: 40px 0 60px 0;
}

.routing-link {
	text-decoration: none;
}

.product-card {
	width: 100%;
	min-height: max-content;
	position: relative;
	padding: 16px;
}

.cart-container {
	width: 52px;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #5ece7b;
	border-radius: 50%;
	position: absolute;
	right: 12%;
	bottom: 14%;
	cursor: pointer;
}

.product-card:hover {
	box-shadow: 0px 4px 35px rgba(168, 172, 176, 0.19);
}

.product-main-image {
	width: 100%;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.outofstock-watermark {
	position: absolute;
	bottom: 50%;
	right: 30%;
	color: #8d8f9a;
	font-size: 24px;
	font-weight: 400;
}

.out-of-stock-img {
	opacity: 0.5;
}

.out-of-stock-text {
	color: #8d8f9a;
}

.product-img {
	width: 90%;
	height: 360px;
	object-fit: contain;
}

.product-name {
	font-weight: 300;
	font-size: 18px;
	line-height: 29px;
	width: 90%;
	margin: 0 auto;
}

.product-price {
	font-weight: 500;
	font-size: 18px;
	line-height: 29px;
	width: 90%;
	margin: 0 auto;
}
